.centered_layout {
  margin: 0 auto;
}

.centered_text {
  text-align: center;
}

.blur {
  filter: blur(4px) !important;
  -webkit-filter: blur(4px) !important;
}

// .ant-modal {
//   > &-close {
//     width: 32px !important;
//     height: 32px !important;
//     border-radius: 16px !important;
//     background: #f5f5f5;
//   }
// }

.ant-modal .ant-modal-close {
  width: 32px !important;
  height: 32px !important;
  border-radius: 16px !important;
  background: #f5f5f5;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.justify-space-between {
  display: flex;
  justify-content: space-between;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.row {
  flex-direction: row !important;
}

.prevent-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.success {
  color: $sapaGreen !important;
}

.loader-icon {
  font-size: 35px !important;
  color: $primaryTextColor;
}

.loader-icon-red {
  font-size: 35px !important;
  color: $error;
}

.sp-disabled {
  cursor: not-allowed !important;
  opacity: 0.4;
  pointer-events: none !important;
}

// margins
.ml-xs {
  margin-left: 5px !important;
}
.ml-sm {
  margin-left: 10px !important;
}
.ml-md {
  margin-left: 15px !important;
}
.ml-lg {
  margin-left: 20px !important;
}

.mr-xs {
  margin-right: 5px !important;
}
.mr-sm {
  margin-right: 10px !important;
}
.mr-md {
  margin-right: 15px !important;
}
.mr-lg {
  margin-right: 20px !important;
}

.mb-xs {
  margin-bottom: 5px !important;
}
.mb-sm {
  margin-bottom: 10px !important;
}
.mb-md {
  margin-bottom: 15px !important;
}
.mb-lg {
  margin-bottom: 20px !important;
}

.mt-xs {
  margin-top: 5px !important;
}
.mt-sm {
  margin-top: 10px !important;
}
.mt-md {
  margin-top: 15px !important;
}
.mt-lg {
  margin-top: 20px !important;
}

.sub-text {
  font-family: $gs-medium;
  font-style: normal;
  font-size: 10px;
  line-height: 14px;
  color: #696969;
  text-transform: uppercase;
}

.amount-text {
  font-family: $cg-bold;
  font-style: normal;
  font-size: 28px;
  line-height: 35px;
  color: #2c264b;
}

.cursor-pointer {
  cursor: pointer !important;
}

@media print {
  *,
  body {
    font-family: $gs-regular !important;
    font-style: normal !important;
    font-size: 11px !important;
    line-height: 144%;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }
  .yes-print,
  .yes-print * {
    display: block !important;
  }
  .electricity-print {
    // width: 1000px !important;
  }
}

.text-error {
  font-size: 11px;
  color: #f04438;
}

.error-page-main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.dropdown-component {
  position: relative;
  height: fit-content;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  background: white;
  margin-top: 8px;
  z-index: 120 !important;
  @media screen and (max-width: 499px) {
    width: 100% !important;
  }
  > li {
    width: 100%;
    border-radius: 8px;
    height: 40px;
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    > p {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 !important;
    }

    &:hover {
      background-color: #f5edff;
    }
  }
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  background-color: transparent;
  z-index: 110 !important;
  cursor: pointer;
}

.complete-wallet-payment {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  > .back {
    top: -12px;
    position: absolute;
    left: -12px;
    transform: scale(0.9);

    @media only screen and (max-width: 500px) {
      transform: none !important;
      > span {
        display: none;
      }
    }
  }
  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;

    @media only screen and (max-width: 500px) {
      > img {
        width: 200px;
        height: auto;
      }
    }
    > h6 {
      width: fit-content;
      font-size: 22px;
      line-height: 27.28px;
      font-weight: 800;
      margin-bottom: 0 !important;

      @media only screen and (max-width: 500px) {
        margin-top: 8px;
      }
    }
    > p {
      text-align: center;
      font-size: 14px;
      line-height: 20.16px;
      margin-bottom: 0 !important;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    gap: 23.38px;
    background: #eaf1ff;
    border: 2px solid rgba(43, 106, 232, 0.2);
    border-radius: 8px;
    // width: 371.93px;
    padding: 25.58px 35.08px;

    @media only screen and (max-width: 500px) {
      width: 100%;
      padding: 27px 18px;
    }

    * {
      margin-bottom: 0 !important;
    }

    > div {
      display: flex;
      gap: 23.38px;
      align-items: flex-end;
      @media only screen and (max-width: 400px) {
        gap: 12px;
      }
      > span {
        display: flex;
        flex-direction: column;
        gap: 4.68px;
        > p {
          font-size: 14.03px;
          line-height: 18.94px;
          font-weight: 500;
          white-space: nowrap;
          @media only screen and (max-width: 400px) {
            font-size: 11px;
          }
        }
        > h6 {
          display: flex;
          align-items: flex-end;
          gap: 4px;
          font-size: 18.71px;
          line-height: 25.26px;
          font-weight: 600;
          @media only screen and (max-width: 400px) {
            font-size: 14px;
          }
          > i {
            all: unset;
            font-size: 13.26px;
            line-height: 20.5px;
            font-weight: 500px;
          }
        }
      }
      > button {
        display: flex;
        align-items: center;
        outline: none;
        background: transparent;
        border: 1.17px solid $primaryTextColor;
        border-radius: 30.4px;
        padding: 4.53px 18.71px;
        gap: 9.35px;
        font-weight: 500;
        font-size: 12.86px;
        line-height: 18.52px;
        color: $primaryTextColor;
        cursor: pointer;
        @media only screen and (max-width: 400px) {
          gap: 4px;
          padding: 4.53px 12px;
        }

        &:hover {
          background: #fcfbfd !important;
        }
      }
    }
  }
}

.electricity-header {
  display: flex;
  justify-content: space-between;

  > a {
    text-decoration: none;
    font-family: $gs-medium !important;
    border: none;
    background: transparent;
    font-size: 16px;
    font-weight: 500;
    color: $primaryTextColor;
    height: fit-content;
    margin-bottom: 0.5em;
    @media only screen and (max-width: 500px) {
      font-size: 14px !important;
    }
  }
}

.payment-error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 74px;
  height: 100%;
  * {
    margin: 0 !important;
  }
  &__top {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: center;
    width: 100%;

    > img {
      width: 205px;
      height: 205px;
    }

    > .error-text-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      > h3 {
        width: fit-content !important;
        text-align: center;
      }

      > span {
        display: flex;
        flex-direction: column;
        > p {
          font-family: $gs-regular;
          font-size: 14px;
          line-height: 18.9px;
          font-weight: 500px;
          text-align: center;
          color: rgba(105, 105, 105, 1);
        }
      }
    }
  }
  &__bottom {
    display: flex;
    width: 100%;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;

    @media only screen and (max-width: 768px) {
      > .button {
        max-width: fit-content !important;
        max-height: 42px !important;
        padding: 0 16px !important;
      }
    }

    @media only screen and (max-width: 380px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.tawk-button-link {
  display: block;
  all: unset;
}
