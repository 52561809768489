

.sp-backnav {
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 16px;

    ion-icon {
        font-size: 24px;
    }

    * {
        cursor: pointer;
    }

    &__title {
        font-family: 'Cabinet Grotesk Bold';
        font-size: 22px !important;
        line-height: 27.28px !important;
        color: #121020 !important;
        font-weight: 500 !important;
    }
}

