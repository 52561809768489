.sp-option-button-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;

    &__row {
      flex-direction: row;
    }

    &__column {
      flex-direction: column;
    }
}
  
.sp-option-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    border: 1px solid #F5F5F5;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    flex: 1;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    }

    &__content {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .sp-option-button__label {
      font-size: 14px;
      line-height: 145%;
    }

    &:hover {
      background-color: #f7f7f7;

      .sp-option-button__uncheckmark {
        display: block;
      }
    }
  
    &.active {
      opacity: 1;
      color: white;
      border: 1px solid #9747FF;

      .sp-option-button__checkmark {
        display: flex;
      }

      .sp-option-button__label {
        font-weight: 600;
      }
  
    }

    .opacity-filter &:not(.active) {
      opacity: 0.9;
    }
  
    .sp-option-button__checkmark {
      width: 15px;
      height: 15px;
      background-color: #318D1F;
      color: #FFF;
      border-radius: 50%;
      display: none;
      justify-content: center;
      align-items: center;
      font-size: 60%;
      line-height: 1;
      right: 10px;
    }

    .sp-option-button__uncheckmark {
        width: 15px;
        height: 15px;
        color: #FFF;
        font-size: 60%;
        line-height: 1;
        right: 10px;
        border: 1px solid #bcbbbb;
        display: none;
        transition: all 0.5s ease-in-out;
        border-radius: 50%;
      }
      .sp-option-button__icon {
        display: flex;
        align-items: center;
      }
  }
  