.service_tile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 76px 20px 22px;
  // margin-bottom: 15px;
  gap: 10px;
  width: 13vw;
  // height: 150px;
  background: #f5edff;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    animation: zoom-in-zoom-out 2s ease-out;

    @keyframes zoom-in-zoom-out {
      0% {
        transform: scale(1, 1);
      }
      50% {
        transform: scale(1.1, 1.1);
      }
      100% {
        transform: scale(1, 1);
      }
    }
  }

  &__icon { 
    width: 45px;
    filter: invert(20%) sepia(100%) saturate(6000%) hue-rotate(260deg) brightness(95%) contrast(110%);
  }

  &__text {
    font-family: $gs-medium;
    font-size: 1em;
    line-height: 144%;
    color: #000000;
    flex-grow: 0;

  }
}

@media screen and (max-width: 991.98px) {
  .homepage {
    &__container {
      &__row-two {
        display: none !important;
      }
    }
  }

  .service_tile {
    width: 25vw !important;
    margin-left: 10px !important;
    margin-right: 10px !important;

    &__text {
      font-size: 1.125em;
    }

    &__wrapper {
      margin-right: 0px !important;
      margin-left: 0px !important;

      > .ant-col {
        padding-right: 0px !important;
        padding-left: 0px !important;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .homepage {
    &__container {
      margin: 0px !important;

      &__row-one {
        padding: 0.7vw !important;
      }
    }
  }

  .service_tile {
    width: 42vw !important;
  }
}
