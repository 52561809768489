.sp-input-button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;

    &__button {
      position: relative;
      border: 1px solid #9747FF;
      width: 82px;
      height: 40px;
      border-radius: 26px;
      cursor: pointer;
      transition: all 0.1s ease-in-out;
      background-color: #FFF;
      color: #9747FF;
      font-size: 14px;
  
      &:hover:not(.disabled) {
        background-color: #f6f6f6;
        color: #9747FF;
      }
  
      &.disabled {
        opacity: 0.3;
        cursor: not-allowed;
        
      }
  
      &.selected {
        background-color: #9747FF;
        border-color: #9747FF;
        color: #FFF;
      }
  
      .sp-input-button-group__checkmark {
        position: absolute;
        top: 5px;
        right: 5px;
        color: #9747FF;
      }
    }
  }
  