.transaction-pin {
  padding-inline: 3em !important;

  &__empty {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__delete {
    cursor: pointer;
  }
}

.pin-secret {
  font-size: 25px !important;
  letter-spacing: 0.2em;

  &__item {
    font-size: 32px;
    letter-spacing: 0.2em;
    position: relative;
    bottom: 7.5px;
    margin-bottom: -15px;
  }
}

@media only screen and (max-width: $small-screen) {
  .transaction-pin {
    // padding-inline: 1em !important;
  }
}
