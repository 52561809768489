.blank-layout {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-top: 3vw;

  &__wrapper {
    background: $white !important;
    padding-inline: 8vw;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block: 10px;
    position: relative;
    // &-empty {
    //   width: 90px;
    // }
    & > .back {
      position: absolute;
      left: 0;
    }

    & > .img {
      background: red;
      &__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &__logo {
    width: 70px;
    object-fit: contain;
  }
  &__login_text {
    padding-inline: 30px;
    cursor: pointer;
  }
  &__button {
    height: 35px;
  }
  &__child {
    margin-left: 0px !important;
  }
}

.back {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 15px;
    background: #f5edff;

    & ion-icon {
      font-size: 10px;
    }
  }
  span {
    margin-left: 10px;
    color: $primaryTextColor;
    font-family: $gs-medium;
  }
}

@media only screen and (max-width: $small-screen) {
  .blank-layout {
    &__wrapper {
      padding-inline: 4vw !important;
    }
  }
}
