.ant-select-selector {
    background   : #f5f5f5 !important;
    border-radius: 26px !important;
}

.ant-select-item-option-selected {
    background: $primaryHue !important;
    border    : 1px solid #9747ff !important;
}


.ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: transparent;
    box-shadow  : 0 0 0 1px #9747ff;
    outline     : 0;
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border-color: transparent;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
}

.ant-select-multiple .ant-select-selection-item {
    background: transparent;
    border    : 1px solid transparent;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width  : 100%;
    height : 48px;
    padding: 9px 11px;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
    position: absolute;
    top: 20%;
    inset-inline-start: 11px;
    inset-inline-end: 11px;
    bottom: 0;
  }