.sp-button {
    &.disabled {
        opacity: 0.5;
    }
    &.full-width {
        width: 100% !important;
    }
    &.ant-btn-primary {
        color           : #fff;
        background-color: #9d53fd;
        box-shadow      : none;
    }

    &.ant-btn-primary:not(:disabled):hover {
        color           : #fff;
        background-color: #bb87ff;
    }

    span {
        color: #fff;
        font-family: "General Sans";
        font-size: 16px;
        font-weight: 500;
        line-height: 21.6px;
    }

    &.ant-btn.ant-btn-lg {
        height : 48px;
        padding-inline-start: 33px;
        padding-inline-end: 33px;
    }

    &.ant-btn.ant-btn-round {
        border-radius: 26px !important;
    }

    &.ant-btn-outline {
        border: 2px solid #9d53fd;

        span {
            color: #9d53fd;
        }
    }
}