.auth {
  &__button {
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .input {
    max-width: 400px;

    /* &_wrapper {
    } */
  }
  .button {
    max-width: 200px;
  }

  & > .form {
    position: relative;
    max-width: 400px;
    margin: 0 auto;
  }
}

.resend {
  font-family: $gs-medium;
  font-style: normal;
  font-size: 14px;
  line-height: 144%;
  text-align: center;
  color: $primaryTextColor;
  cursor: pointer;
}

.whatsapp {
  font-family: $gs-medium;
  font-style: normal;
  font-size: 11px;
  line-height: 144%;
  text-align: center;
  color: $primaryTextColor;
  cursor: pointer;
}

.character {
  position: absolute;
  bottom: 2px;
  right: 0%;
  @media screen and (max-width: 600px) {
    width: 300px;
    height: 300px;
    display: none;
  }
}

.input-inline {
  display: flex;
  justify-content: space-between;

  & .input_wrapper {
    &:nth-child(1) {
      margin-right: 10px;
    }
  }
}

#slide {
  // position: absolute;
  // left: -100px;
  // width: 400px;
  // -webkit-animation: slide 0.1s forwards;
  // -webkit-animation-delay: 0.1s;
  // animation: slide 0.1s forwards;
  // animation-delay: 0.1s;
}

@-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}

.layout {
  &__inner {
    min-height: 400px;
    position: relative;
    max-width: 400px;
    margin: 0 auto;
  }
}

.light-color {
  color: #b1b1b1;
}

.signup-base {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  margin-top: 96px;
  min-height: calc(100dvh - 3vw - 68.52px - 96px);
  @media screen and (max-width: 650px) {
    margin-top: 40px;
  }
  > img {
    position: absolute;
    bottom: 0;
    right: 5%;
    @media screen and (max-width: 650px) {
      width: 300px;
      height: 300px;
    }
  }
  &__btn-container {
    display: flex;
    flex-direction: column;
    gap: 21px;
    width: 580px;
    @media screen and (max-width: 650px) {
      width: 100%;
    }
    @media screen and (max-width: 459px) {
      transform: scale(0.8);
    }
    > button {
      position: relative;
      display: flex;
      gap: 30px;
      align-items: center;
      padding: 22px 30px;
      border-radius: 12px;
      border: 1px solid #dfc6ff;
      background: rgba(234, 241, 255, 0.3);
      max-height: 104px;
      cursor: pointer;

      @media screen and (max-width: 650px) {
        width: 90%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        padding: 12px;
        gap: 10px;

        // > span {
        //   max-width: 300px;
        // }

        &:last-child {
          top: 42.5%;
        }
      }
      @media screen and (max-width: 459px) {
        position: relative !important;
        width: 130% !important;
        &:last-child {
          top: 92.5% !important;
        }
      }
      > svg {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        @media screen and (max-width: 650px) {
          right: 12px;
          min-width: 10px !important;
          min-height: 10px !important;
          max-width: 10px !important;
          max-height: 10px !important;
        }
      }
      > i {
        width: 60px;
        height: 60px;
        border-radius: 60px;
        background: rgba(234, 241, 255, 0.333);
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 650px) {
          width: 30px;
          height: 30px;
          border-radius: 30px;
          background: rgba(234, 241, 255, 0.463);
          > svg {
            min-width: 14px !important;
            min-height: 14px !important;
            max-width: 14px !important;
            max-height: 14px !important;
          }
        }
      }
      > span {
        display: flex;
        flex-direction: column;
        gap: 8px;
        > h6 {
          font-family: $gs-medium;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 144%;
          color: #000000;
          flex-grow: 0;
          margin-bottom: 0;
          width: fit-content;
        }
        > p {
          font-family: $gs-regular;
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 144%;
          color: #696969;
          flex-grow: 0;
          margin-bottom: 0;
          text-align: left;
        }
      }
    }
  }
}
