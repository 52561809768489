.coming-soon {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;

  & .content {
    flex-direction: column;
    text-align: center;

    // & .text {
    //   //   line-height: 15px;
    // }
  }
}
