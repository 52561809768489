.auth {
  &__button {
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .input {
    &_wrapper {
      max-width: 400px;
      margin: 0 auto;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
    }
  }
  .button {
    max-width: 200px;
  }
}

.resend {
  font-family: $gs-medium;
  font-style: normal;
  font-size: 14px;
  line-height: 144%;
  text-align: center;
  color: $primaryTextColor;
  cursor: pointer;
}

.whatsapp {
  font-family: $gs-medium;
  font-style: normal;
  font-size: 11px;
  line-height: 144%;
  text-align: center;
  color: $primaryTextColor;
  cursor: pointer;
}

.character {
  float: right;
}
