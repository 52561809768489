
.sp-copy {
    &__button {
        cursor: pointer;
    }

    &__button.sp-button span {
        font-size: 12.58px;
        display: flex;
        align-content: space-between;
        align-items: center;
      }
}