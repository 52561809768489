.bill-receipt {
  width: auto;
  margin: 50px auto;
  background: #fafafa;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    width: fit-content;

    @media only screen and (max-width: 768px) {
      > .button {
        max-width: fit-content !important;
        max-height: 42px !important;
        padding: 0 16px !important;
      }
    }
  }

  &__recurring-payment-buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    width: fit-content;
    @media only screen and (max-width: 768px) {
      > .button {
        max-width: fit-content !important;
        max-height: 42px !important;
        padding: 0 16px !important;
      }
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    font-size: 16px;
    border-bottom: 1px #f5ecec solid;
    padding-bottom: 15px;

    &:last-child {
      border-bottom: none !important;
      padding-bottom: 0px !important;
    }

    & > .field-label {
      font-family: $gs-regular;
      font-style: normal;
      font-size: 14px;
      line-height: 144%;
      color: #56516f;
    }

    & > .field-value {
      font-family: $gs-medium;
      font-style: normal;
      font-size: 14px;
      line-height: 144%;
      text-align: right;
      color: #121020;
    }

    & > .sub-field-value {
      font-family: $gs-regular;
      font-size: 14px;
      line-height: 144%;
      text-align: right;
      color: #ccc;
      text-transform: uppercase;
    }

    & .multi-value {
      & > .field-value {
        font-family: $gs-medium;
        font-size: 14px;
        line-height: 144%;
        text-align: right;
        color: #121020;
      }
      & > .sub-field-value {
        font-family: $gs-regular;
        font-size: 11px;
        line-height: 144%;
        text-align: right;
        color: #605c78;
        text-transform: uppercase;
      }
    }
  }
}

.logo {
  width: 72px;
  height: 72px;
  border-radius: 36px;
  margin-bottom: 20px;
}

.sub-field-value {
  font-family: $gs-regular;
  font-size: 11px;
  line-height: 144%;
  color: #898989;
  text-transform: uppercase;
}

.amount-field {
  font-family: $gs-medium;
  font-size: 18px;
  line-height: 144%;
  color: #121020;
}

.print-body {
  width: "100%";
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  img {
    display: block;
  }
 /*  background: #133E8734;
  background-blend-mode: multiply; */
}

ion-icon[name="copy-outline"] {
  color: $primaryTextColor;
}

.recurring-payment_delete {
  display: flex;
  width: 52px;
  height: 52px;
  justify-content: center;
  align-items: center;
  border-radius: 26px;
  background: #fdf0ee;
  border: none;
  cursor: pointer;
}

.print-receipt-footer {
  display: flex;
  justify-content: center;
  margin-top: 64px;
  padding: 32px; 
  background: #F9F9F9; 
  border-radius: 8px;
  strong {
      display: inline-block !important;
  }
}