

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus {
    border    : none;
    box-shadow: none;
    z-index   : 1;
}

.sp-input {
    height          : 48px;
    border-radius   : 26px;
    border          : none;
    padding         : 16px 24px;
    background-color: #F5F5F5;

    &:focus, &.ant-input-affix-wrapper-focused  {
        border: 1px solid #9747FF;
        box-shadow: 0 0 0 1px #9747FF !important;
    }

    &__spin {
        color: #9747FF;
    }

    &.sp-outlined {
        border: 2px solid #F5F5F5;
    }
}
