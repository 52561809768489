// Font weight utilities
.font-weight-thin {
    font-weight: 100;
  }
  
  .font-weight-light {
    font-weight: 300;
  }
  
  .font-weight-regular {
    font-weight: 400;
  }
  
  .font-weight-medium {
    font-weight: 500;
  }
  
  .font-weight-semibold {
    font-weight: 600;
  }
  
  .font-weight-bold {
    font-weight: 700;
  }
  
  .font-weight-extrabold {
    font-weight: 800;
  }
  
  .font-weight-black {
    font-weight: 900;
  }
  
  // Color utilities
  .text-success {
    color: #318D1F; // Adjust color as needed
  }
  
  .text-danger,  .text-failed {
    color: #dc3545; // Adjust color as needed
  }
  
  .text-info {
    color: #17a2b8; // Adjust color as needed
  }
  
  .text-warning {
    color: #ffc107; // Adjust color as needed
  }
  
  .bg-success {
    background-color: #318D1F; // Adjust color as needed
  }
  
  .bg-danger {
    background-color: #dc3545; // Adjust color as needed
  }
  
  .bg-info {
    background-color: #17a2b8; // Adjust color as needed
  }
  
  .bg-warning {
    background-color: #ffc107; // Adjust color as needed
  }

// Generate font sizes from 1px to 52px
@for $size from 1px through 52px {
  // Generate a utility class for each font size
  .font-size-#{$size} {
    font-size: $size;
  }
}

.text-center {
  text-align: center !important;
}
