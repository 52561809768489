span,
div,
p,
label {
  font-family: "General Sans";
  // font-style: normal;
  // font-weight: 400;
  font-size: 14px;
  // font-size: 0.97vw;
  line-height: 180%;
  // color: #9a96b8;
  color: $textColor;
}

h2 {
  font-family: $gs-bold;
  font-size: 64px;
  line-height: 79px;
  color: #2c264b;
}

h3 {
  font-family: $cg-bold;
  font-size: 22px;
  line-height: 27px;
  color: #121020;
}

h4 {
  font-family: $cg-bold;
  font-size: 24px;
  line-height: 30px;
  color: #121020;
}

.text-align-center {
  text-align: center !important;
}

a {
  color: $primaryTextColor;
}

a:hover {
  color: #6509dd;
}

.text-bold {
  font-family: $gs-medium !important;
}