.sp-keypad-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .sp-keypad-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    margin-top: 1rem;
  }
  
  .sp-keypad-button {
    width: 60px;
    height: 60px;
    background-color: transparent;
    border: 1px solid #9747FF;
    border-radius: 50%;
    font-size: 16px;
    color: #9747FF;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    &.sp-active, &:hover {
      background-color: #9747FF;
      color: #fff;
    }
  
    &:focus {
      outline: none;
    }
  }
  