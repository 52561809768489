.homepage {
  background-color: $white !important;
  // margin-inline: 0px !important;

  &__img {
    width: 90%;
  }
  &__container {
    margin: 0vw 4.86vw;

    &__row-one {
      padding: 25px;
    }
    &__row-two {
      display: flex;
      align-items: flex-end;
    }

    &__bookmark {
      margin-left: 2px;
      margin-top: 32px;
    }

    @media (max-width: 600px) {
      &__bookmark {
        margin-left: 12px;
        margin-top: 32px;
      }
    }
  }
  &_content__img_wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__title {
    font-size: 48px;
    font-weight: 800;
    max-width: 536px;
    line-height: 52px;

  }
   
}

// .service_tile {
//   // &__wrapper {
//   //   display: grid;
//   //   grid-template-columns: repeat(3, 1fr);
//   //   // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//   //   // grid-template-columns: repeat(3, minmax(200px, 1fr));
//   //   gap: 10px;
//   //   grid-gap: 20px;
//   //   grid-auto-rows: minmax(100px, auto);
//   // }
// }
