.dashboard {
  // padding: 0 50px;

  &__layout {
    background: $bgColor;
    padding: 24px 0;
  }
  &__content {
    min-height: 280px;
    margin-left: 30px;
  }
  &__side {
    background: $white;
    min-width: 280px !important;
  }
  &__menu {
    // color: red;
    &_item {
      padding-block: 32px;
      span {
        height: 23px;
      }
      ion-icon {
        float: right;
        height: 100%;
        font-size: 18px;
      }
    }
  }
}

.card {
  background: $white;
  min-width: 280px !important;
  padding: 15px 18px;
  border-radius: 8px;
}
