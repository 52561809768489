.aboutUs {
  width: 100%;
  max-width: 100%;
  padding-top: 4rem;
  padding-bottom: 100px;
  margin-top: -24px;
  &__hero {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 0 7vw 119.4px 7vw;

    &-left {
      display: flex;
      flex-direction: column;
      gap: 40px;
      width: 50%;
      align-self: center;
      height: fit-content !important;
      h1 {
        font-family: $cg-bold;
        font-style: normal;
        font-size: 80px;
        line-height: 79px;
        color: #000000;
        margin: 0;
      }
      p {
        font-family: $gs-regular;
        font-style: normal;
        // font-size: 18px;
        font-size: 1.1em;
        line-height: 200%;
        color: #000000;
        margin: 0;
      }
      &-btn {
        width: 167px;
        max-width: 167px;
        min-width: 167px;
        height: 52px;
        border-radius: 26px !important;
        &:hover {
          background: #3f1e6b !important;
        }
        span {
          font-family: $gs-medium !important;
          // font-weight: 500 !important;
        }
      }
      div {
        &:first-child {
          display: flex;
          flex-direction: column;
          gap: 32px;
        }
        &:nth-child(2) {
          display: flex;
          flex-direction: column;
          gap: 13.84px;
          p {
            font-family: $gs-medium;
            font-style: normal;
            font-size: 16px;
            line-height: 144%;
            color: #898989;
            margin: 0;
            margin-left: 4px;
          }
          span {
            display: flex;
            gap: 8px;
            button {
              width: fit-content;
              padding: 0;
              border: none;
              outline: none;
              background: #f5edff;
              border-radius: 30.5894px;

              img {
                width: 141.24px;
                max-width: 141.24px;
                height: 39.77px;
                max-height: 39.77px;
              }
            }
          }
        }
      }
    }
    &-right {
      width: 45%;
      position: relative;
      max-height: 696.27px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: relative;
        // &::after {
        //   content: "";
        //   position: absolute;
        //   bottom: 0;
        //   width: 100px;
        //   height: 10px;
        //   background-color: rgba(
        //     0,
        //     0,
        //     0,
        //     0.5
        //   );
        //   box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.5);
        // }
      }
      span {
        position: absolute;
        width: 220px;
        height: 56.6px;
        background: #ffffff;
        p {
          margin: 0;
          font-family: $gs-medium;
          font-style: normal;
          font-size: 15px;
          line-height: 144%;
          color: #181529;
        }
      }
      .span1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 13.3812px;
        left: -60px;
        top: 0;
        gap: 8.92px;
        box-shadow: 0px 0px 4.46039px rgba(0, 0, 0, 0.04),
          -8.92078px 8.92078px 17.8416px rgba(0, 0, 0, 0.08);
        border-radius: 13.3812px 13.3812px 0px 13.3812px;
      }
      .span2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 13.3812px;
        right: -60px;
        top: 34%;
        gap: 8.92px;
        box-shadow: 0px 0px 4.46039px rgba(0, 0, 0, 0.04),
          -8.92078px 8.92078px 17.8416px rgba(0, 0, 0, 0.08);
        border-radius: 13.3812px 13.3812px 13.3812px 0px;
      }
      .span3 {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 13.3812px;
        left: -60px;
        bottom: 0;
        gap: 8.92px;
        box-shadow: 0px 0px 4.46039px rgba(0, 0, 0, 0.04),
          -8.92078px 8.92078px 17.8416px rgba(0, 0, 0, 0.08);
        border-radius: 13.3812px 0px 13.3812px 13.3812px;
      }
    }
  }
  &__question-section {
    height: 492px;
    padding-top: 120px;
    div {
      width: 685px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 32px;
      h3 {
        font-family: $cg-bold;
        font-style: normal;
        font-size: 36px;
        line-height: 45px;
        text-align: center;
        color: #000000;
        margin: 0;
      }
      p {
        font-family: $gs-regular;
        font-style: normal;
        // font-size: 18px;
        font-size: 1.1em;
        line-height: 200%;
        text-align: center;
        color: #000000;
        margin: 0;
      }
    }
  }
  &__features {
    padding: 120px 0 240px 0;
    &-card {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: fit-content;
      background: #fbfafabd;
      border-radius: 24px;
      padding: 48px 48px 0 48px;
      margin: 0 auto;
      &_tab-nav {
        display: flex;
        width: fit-content;
        background: #f5edff;
        border-radius: 26px;
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 15px 24px;
          background: #f5edff;
          border-radius: 26px;
          border: none;
          outline: none;
          font-family: $gs-medium;
          font-style: normal;
          font-size: 14px;
          line-height: 144%;
          text-align: center;
          color: $primaryTextColor;
          max-height: 40px;
          cursor: pointer;
          transition: background-color 0.1s ease-in;
          &.active {
            background-color: $primaryTextColor;
            color: #ffffff;
            &:hover {
              background-color: #3f1e6b;
              color: #ffffff;
            }
          }
        }
      }
      &_content {
        display: flex;
        gap: 63px;
        .text-span {
          display: flex;
          flex-direction: column;
          gap: 24px;
          width: 432px;
          height: fit-content;
          margin-top: 68px;
          h3 {
            font-family: $cg-bold;
            font-style: normal;
            font-size: 36px;
            line-height: 45px;
            color: #000000;
            text-align: left;
            margin: 0;
            width: 320px;
          }
          p {
            font-family: $gs-regular;
            font-style: normal;
            // font-size: 18px;
            font-size: 1.1em;
            line-height: 200%;
            color: #000000;
            margin: 0;
          }
        }
        .img-span {
          width: 389px;
          height: 462px;
          border-radius: 24px 24px 0px 0px;
          overflow: hidden;
          &.blue-bg {
            background: linear-gradient(
              180.09deg,
              #eaf1ff 38.65%,
              rgba(234, 241, 255, 0) 99.93%
            );
          }
          &.green-bg {
            background: linear-gradient(
              180.09deg,
              #eaf4e9 38.65%,
              rgba(234, 244, 233, 0) 99.93%
            );
          }
          &.pink-bg {
            background: linear-gradient(
              180.08deg,
              #fdf0ee 37.68%,
              rgba(253, 240, 238, 0) 99.93%
            );
          }
          img {
            width: 100%;
            object-fit: cover;
            transition: all 0.7s ease-in-out;
          }
        }
      }
    }
  }
  &__providers {
    width: 100%;
    padding: 0 20px;
    div {
      display: flex;
      flex-direction: column;
      gap: 60px;
      h3 {
        font-family: $cg-bold;
        font-style: normal;
        font-size: 36px;
        line-height: 45px;
        text-align: center;
        color: #000000;
      }
      span {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        align-items: center;
        figure {
          width: 100px;
          height: 85px;
          filter: drop-shadow(0px 8px 20px rgba(177, 177, 177, 0.1));
          border-radius: 12px;
          background-color: white;
          margin: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  &__faqs {
    width: 100%;
    padding: 240px 0 260px 0;
    .rotated {
      transform: rotate(180deg) !important;
    }
    &_inner {
      width: fit-content;
      display: flex;
      align-items: center;
      gap: 100px;
      margin: 0 auto;
      figure {
        position: relative;
        width: 380px;
        border-radius: 24px;
        margin: 0;
        span {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          padding: 12px;
          width: 170px;
          top: -24px;
          left: -93px;
          background: #ffffff;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04),
            -8px 8px 16px rgba(0, 0, 0, 0.08);
          border-radius: 12px 12px 0px 12px;
          svg {
            min-width: 17px;
            min-height: 17px;
          }
          p {
            font-family: $gs-medium;
            font-style: normal;
            font-size: 14px;
            line-height: 19px;
            color: #000000;
            margin: 0;
          }
        }
        img {
          width: 100%;
        }
      }
      &-right {
        display: flex;
        flex-direction: column;
        gap: 32px;
        width: 500px;
        h3 {
          font-family: $cg-bold;
          font-style: normal;
          font-size: 36px;
          line-height: 45px;
          color: #000000;
          margin: 0;
        }
        &-form {
          display: flex;
          flex-direction: column;
          gap: 24px;
          padding: 16px;
          border-radius: 12px;
          border: 0.5px solid #b1b1b1;
          transition: all 0.3s ease-in-out;
          cursor: pointer;
          span {
            display: flex;
            justify-content: space-between;
            align-items: center;
            h6 {
              font-family: $gs-medium;
              font-style: normal;
              // font-size: 18px;
              font-size: 1.1em;
              line-height: 144%;
              color: $sapaBlack;
              margin: 0;
            }
          }
          p {
            display: none;
            font-family: $gs-regular;
            font-style: normal;
            font-size: 14px;
            line-height: 144%;
            color: #000000;
            margin: 0;
            b {
              font-family: $gs-regular !important;
              // font-weight: 400 !important;
            }
          }
          &.active {
            border: 5px solid rgba(151, 71, 255, 0.1);
            filter: drop-shadow(0px 2px 40px rgba(177, 177, 177, 0.05));
            p {
              display: block;
            }
          }
        }
        .faqs-btn {
          display: block;
          width: fit-content;
          height: fit-content;
          max-width: fit-content;
          max-height: fit-content;
          min-height: fit-content;
          max-height: fit-content;
        }
        button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 15px 40px;
          gap: 8px;
          width: 196px;
          max-height: 52px;
          border: 1px solid #9747ff;
          border-radius: 26px;
          outline: none;
          background: transparent;
          font-family: $gs-medium;
          font-style: normal;
          font-size: 16px;
          line-height: 144%;
          text-align: center;
          color: #9747ff;
          cursor: pointer;
          &:hover {
            background-color: #f5edff;
          }
        }
      }
    }
  }
  &__Cta {
    width: 100%;
    div {
      background-image: url(../../assets//images/cta_bg.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: fit-content;
      padding: 56px;
      display: flex;
      gap: 140px;
      align-items: flex-end;
      border-radius: 24px;
      margin: 0 auto;
      h3 {
        font-family: $cg-bold;
        font-style: normal;
        font-size: 48px;
        line-height: 144%;
        color: $textColor;
        margin: 0;
        width: 502px;
        text-align: left;
      }
      a {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 15px 40px;
        gap: 8px;
        width: 201px;
        height: 52px;
        background: #9747ff;
        border-radius: 26px;
        border: none;
        outline: none;
        font-family: $gs-medium;
        font-style: normal;
        font-size: 16px;
        line-height: 144%;
        text-align: center;
        color: #ffffff;
        svg {
          min-width: 17px;
          min-height: 17px;
          stroke: white !important;
          fill: white !important;
          path {
            fill: white !important;
          }
        }
        &:hover {
          background-color: #3f1e6b;
        }
      }
    }
  }
}

.swiper {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  padding: 16px;
  gap: 16px;
}

// responsive styles

@media screen and (max-width: 1182px) {
  .aboutUs {
    &__faqs {
      &_inner {
        figure {
          span {
            left: -63px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1273px) {
  .aboutUs {
    &__providers {
      div {
        span {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
          justify-content: center;
          align-items: center;
          width: 70%;
          margin: 0 auto;
          figure {
            width: 150px !important;
            justify-self: center;
            align-self: center;
            img {
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1130px) {
  .aboutUs {
    &__hero {
      &-left {
        h1 {
          font-size: 60px;
          line-height: 60px;
        }
      }
    }
    &__faqs {
      &_inner {
        figure {
          span {
            left: -13px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1038px) {
  .aboutUs {
    &__hero {
      gap: 8px;
      justify-content: center;
      &-left {
        gap: 24px;
        h1 {
          font-size: 40px;
          line-height: 40px;
        }
        p {
          font-size: 14px;
        }
        div {
          &:first-child {
            gap: 24px;
          }
        }
        &-btn {
          width: 120px !important;
          height: 42px !important;
          min-width: 120px !important;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    &__features-card_content {
      gap: 32px;
      .text-span {
        width: 380px;
      }
    }
    &__faqs {
      &_inner {
        flex-direction: column;
        width: 80%;
        figure {
          width: 100%;
          span {
            left: -13px;
          }
        }
        &-right {
          width: 100%;
        }
      }
    }
    &__Cta {
      div {
        width: 80%;
        a {
          justify-content: center;
          align-items: center;
          width: 196px !important;
          min-width: 190px !important;
          padding: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 884px) {
  .aboutUs {
    &__hero {
      gap: 40px;
      justify-content: flex-start;
      flex-direction: column;
      &-left {
        gap: 24px;
        width: 100%;
        .stores_btn {
          img {
            width: 100px !important;
            height: 35px !important;
          }
        }
        h1 {
          font-size: 60px;
          line-height: 60px;
        }
        p {
          font-size: 20px;
        }
        div {
          &:first-child {
            gap: 24px;
          }
        }
        &-btn {
          width: 120px !important;
          height: 42px !important;
          min-width: 120px !important;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &-right {
        width: 75%;
        margin: 0 auto;
      }
    }
    &__question-section {
      div {
        width: 80%;
      }
    }
    &__features-card {
      width: 90%;
    }
    &__features-card_content {
      gap: 32px;
      flex-direction: column-reverse;
      .img-span {
        width: 100%;
        img {
          height: 100% !important;
          object-fit: contain !important;
        }
      }
      .text-span {
        width: 100%;
        margin-top: 0;
        h3 {
          text-align: center;
          width: fit-content;
        }
        p {
          text-align: center;
        }
      }
    }
    &__Cta {
      div {
        flex-direction: column;
        align-items: center;
        h3 {
          width: fit-content;
          text-align: center;
        }
      }
    }
  }
}

//mobile screens

@media screen and (max-width: 504px) {
  .aboutUs {
    &__features-card {
      &_tab-nav {
        button {
          padding: 12px !important;
          height: 32px !important;
        }
      }
    }
  }
}
@media screen and (max-width: 441px) {
  .aboutUs {
    &__hero {
      &-right {
        span {
          width: 150px;
          height: 36.6px;
          p {
            font-size: 12px;
            line-height: 50%;
          }
          svg {
            width: 20px;
            height: 20px !important;
          }
        }
        .span1,
        .span3 {
          left: -50px;
        }
        .span2 {
          right: -30px;
          p {
            line-height: 100%;
          }
        }
      }
    }
    &__features-card {
      width: 95%;
      padding: 24px 12px;
    }
    .img-span {
      height: 400px !important;
    }
    &__faqs {
      &_inner {
        &-right {
          width: 100%;
          button {
            width: 140px !important;
            height: 42px !important;
            font-size: 14px;
            line-height: 100%;
            padding: 0;
          }
        }
      }
    }
  }
}
