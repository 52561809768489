


.sp-modal .ant-modal-content {
    position: relative;
    padding: 0;
    border-radius: 12px !important;
  }
  
  .sp-modal.zoom-out {
    animation: zoomOut 0.3s forwards;
  }
  
  @keyframes zoomOut {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(0);
    }
  }

  .sp-modal.fade-out {
    animation: fadeOut 0.1s forwards;
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0.9);
    }
  }
  
  
  .sp-modal__wrapper {
    padding: 50px 48px;
  }

  .sp-modal__close-btn {
    position: absolute;
    top: 24px;
    right: 24px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    outline: none;
    color: #000;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
  
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  